body {
  font-family: "Source Sans Pro", sans-serif, Arial, Univers;
  font-size: 15px;
  line-height: 1.66667;
  color: #222;
  background-color: #fff;
  font-weight: 400;
}

// @font-face {
//     font-family: 'Source Sans Pro';
//     font-weight: 300;
//     font-style: normal;
//     src: local("Source Sans Pro Light"), url("../fonts/SansPro/SourceSansPro-Light.otf.woff2") format("woff2"), url("../fonts/SansPro/SourceSansPro-Light.otf.woff") format("woff")
// }

// @font-face {
//     font-family: 'Source Sans Pro';
//     font-weight: 400;
//     font-style: normal;
//     src: local("Source Sans Pro Regular"), url("../fonts/SansPro/SourceSansPro-Regular.otf.woff2") format("woff2"), url("../fonts/SansPro/SourceSansPro-Regular.otf.woff") format("woff")
// }

// @font-face {
//     font-family: 'Source Sans Pro';
//     font-weight: normal;
//     font-style: italic;
//     src: local("Source Sans Pro Italic"), url("../fonts/SansPro/SourceSansPro-It.otf.woff2") format("woff2"), url("../fonts/SansPro/SourceSansPro-It.otf.woff") format("woff")
// }

// @font-face {
//     font-family: 'Source Sans Pro';
//     font-weight: 500;
//     font-style: normal;
//     src: local("Source Sans Pro Semibold"), url("../fonts/SansPro/SourceSansPro-Semibold.otf.woff2") format("woff2"), url("../fonts/SansPro/SourceSansPro-Semibold.otf.woff") format("woff")
// }

// @font-face {
//     font-family: 'Source Sans Pro';
//     font-weight: 700;
//     font-style: normal;
//     src: local("Source Sans Pro Bold"), url("../fonts/SansPro/SourceSansPro-Bold.otf.woff2") format("woff2"), url("../fonts/SansPro/SourceSansPro-Bold.otf.woff") format("woff")
// }

h4 {
  font-size: 16px;
  color: #036;
  font-weight: normal;
}

option {
  min-height: 50px;
}

.nopadding {
  padding: 0;
}

.custom-dropdown label {
  display: inline-block;
  margin-bottom: 0.2rem;
}
label {
  margin-bottom: 2px;
}

.md-font {
  font-size: 20px;
}

.sm-font {
  font-size: 12px;
}

.error .form-control {
  border: 1px solid red;
}

.error-msg {
  color: red;
}

.divider-dark {
  border-color: #a7a7a7;
}

.form-control::placeholder {
  color: #bbb;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbb;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbb;
}

.search-arrow {
  margin-top: 30px;
  font-size: 32px;
  cursor: pointer;
}

/* *****************************************Top Header CSS******************************************************* */
.user-name::after {
  display: none;
}

.custom-icon {
  margin-left: 5px;
  margin-top: 3px;
}

.top-header {
 /* background-color: #003366;*/
  padding: 10px 0;
}

.top-header .user-name {
  color: #fff;
  text-decoration: none;
}
.top-link {
  padding: 0;
  border-radius: 0;
}
.top-link .dropdown-item {
  line-height: 22px;
  padding: 5px 15px;
  border-bottom: 1px solid #f2f2f2;
  display: block;
  clear: both;
  white-space: nowrap;
  color: #333;
  text-decoration: none;
  font-size: 14px;
}

.top-link .dropdown-item.active,
.dropdown-item:active {
  background-color: #fff;
}
/* ************************************End Top Header CSS*********************************************** */
/* **************************************Header CSS***************************************************** */
.brand-logo {
  height: 30px;
  width: 114px;
}

.custom-navbar {
  background-color: #fff;
  padding: 0.5rem 0;
  min-height: 60px;
}

.custom-navbar .navbar-nav a {
  /*margin-left: 10px;*/
  color: #333 !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.custom-navbar .navbar-toggler {
  border: none;
  outline: none;
}

.custom-navbar .navbar-toggler-icon {
  width: 1.2em;
}
.custom-navbar img {
  height: 57px;
  width: 175px;
}
.login-label h1 {
  color: #fff;
  padding: 20px 0;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

@media (min-width: 768px) {
  .login-label h1 {
    padding: 30px 0;
    font-size: 42px;
  }

  .custom-navbar {
    min-height: 82px;
  }

  .brand-logo {
    height: 57px;
    width: 175px;
  }

  .login-label::after {
    background-image: image("ribbon.png");
  }
}

/* *******************************End Header CSS*************************************************   */
//Remove Error
.loader-wrapper {
  z-index: 1040 !important;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
}

.modal-label {
  font-size: 16px;
  font-weight: 600;
}

.sub-div {
  margin-bottom: 5px;
  border-bottom: 2px solid #eee;
  font-size: 14px;
  font-weight: 600;
}

.business-no {
  font-size: 18px;
  line-height: 28px;
}

.dashboard-btn {
  font-size: 20px;
  font-weight: 600;
}

.box-container {
  border: 1px solid #cfcfd0;
  padding: 5px;
  background-color: #ececec;
  font-style: italic;
}

.required label:after {
  color: #e32;
  content: " *";
  display: inline;
  font-size: 16px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.3rem;
  line-height: 20px;
  /*height: 34px;*/
  color: #000;
  font-size: 16px;
  line-height: 1.66667;
  background-color: #fff;
  /* background-image: none; */
  border: 1px solid #ccc;
  border-radius: 2px;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

table .form-control {
  height: 32px;
  font-size: 14px;
}

.btn {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 30px;
  margin-left: 4px;
  border-radius: 2px;
  border-width: 1px;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #009ce5;
  border-color: #009ce5;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0079b2;
  border-color: #0072a8;
}

.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0066cc;
  border-color: #0066cc;
}

.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #00618e;
  border-color: #004566;
}

.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-image: none;
}

.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-secondary.disabled:active,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #009ce5;
  border-color: #009ce5;
}

.btn-primary .badge {
  color: #009ce5;
  background-color: #fff;
}

.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #036;
  background-color: #fff;
  border-color: #036;
}

.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary:active.focus,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #009ce5;
  background-color: #e6e6e6;
  border-color: #004566;
}

.btn-secondary:hover {
  color: #009ce5;
  background-color: #e6e6e6;
  border-color: #0072a8;
}

.btn-secondary:active,
.btn-secondary.active,
.open > .btn-secondary.dropdown-toggle {
  color: #009ce5;
  background-color: #e6e6e6;
  border-color: #0072a8;
}

.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary:active.focus,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.btn-secondary.active.focus,
.open > .btn-secondary.dropdown-toggle:hover,
.open > .btn-secondary.dropdown-toggle:focus,
.open > .btn-secondary.dropdown-toggle.focus {
  color: #009ce5;
  background-color: #d4d4d4;
  border-color: #004566;
}

.btn-secondary:active,
.btn-secondary.active,
.open > .btn-secondary.dropdown-toggle {
  background-image: none;
}

.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary.disabled.focus,
.btn-secondary[disabled]:hover,
.btn-secondary[disabled]:focus,
.btn-secondary[disabled].focus,
fieldset[disabled] .btn-secondary:hover,
fieldset[disabled] .btn-secondary:focus,
fieldset[disabled] .btn-secondary.focus {
  background-color: #fff;
  border-color: #009ce5;
}

.btn-secondary .badge {
  color: #fff;
  background-color: #009ce5;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  /*opacity: .65;
    filter: alpha(opacity=65);*/
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #e6e6e6;
}

.btn.disabled:hover,
.btn[disabled]:hover,
fieldset[disabled] .btn:hover {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #e6e6e6;
}

.input-group-append .btn {
  /*padding: 3px 12px;
            min-width: auto;*/
  margin-left: 0;
}

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.box-label {
  font-size: 18px;
  font-weight: 600;
}

#tabs {
  background: #007b5e;
  color: #eee;
}

#tabs h6.section-title {
  color: #eee;
}

#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #333;
  background-color: transparent;
  border-color: transparent transparent #fff;
  border-bottom: #ff9900 4px solid !important;
}

.nav-tabs .nav-link:hover {
  border-bottom: 1px solid transparent;
}

#tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #eee;
  font-size: 20px;
}

.nav-fill .nav-item {
  flex: none;
  font-size: 15px;
  color: #333;
}

.policy-admin .nav-fill .nav-item {
  flex: none;
  font-size: 15px;
  color: #333;
}

.custom-nav .dropdown-menu {
  padding: 0;
  border-radius: 0;
}

.custom-nav .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #f2f2f2;
  font-weight: 400;
  font-size: 14px;
}

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.nav-tabs.circle-label .nav-link {
  border: none;
}

table.dataTable {
  border-top: 0;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 15px 8px;
  border-left: 1px solid #ddd;
  color: #fff;
  background: #0066cc;
  font-weight: 400;
  font-size: 15px;
  text-align: left;
  line-height: 16px;
}

table.dataTable > tbody > tr.child ul {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  left: 4px;
  position: absolute;
  font-size: 14px;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0;
  font-family: "FontAwesome";
  content: "\f067";
  color: #fff;
  background: #009ce5;
  border-radius: 12px;
  border: none;
  box-shadow: none;
  height: 20px;
  width: 20px;
  vertical-align: text-top;
  line-height: 22px;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child:before {
  content: "\f068";
  background: #ffc107;
}

table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  position: absolute;
  display: block;
  opacity: 0.125;
  right: 1px !important;
  line-height: 9px;
  font-size: 1.5em !important;
}

// table.dataTable thead .sorting:before,
// table.dataTable thead .sorting_asc:before,
// table.dataTable thead .sorting_desc:before,
// table.dataTable thead .sorting_asc_disabled:before,
// table.dataTable thead .sorting_desc_disabled:before {
//     right: 1em;
//     content: "\f0de";
//     font-family: FontAwesome;
//     top: 15px;
//     right: 15px;
// }

// table.dataTable thead .sorting:after,
// table.dataTable thead .sorting_asc:after,
// table.dataTable thead .sorting_desc:after,
// table.dataTable thead .sorting_asc_disabled:after,
// table.dataTable thead .sorting_desc_disabled:after {
//     right: 0.5em;
//     content: "\f0d7";
//     font-family: FontAwesome;
//     top: 18px;
//     right: 15px;
// }

.dtr-details {
  width: 100%;
}

.dtr-details .dtr-title {
  width: 50%;
}

// table.dataTable thead th,
// table.dataTable thead td {
//     padding: 15px 28px 15px 10px !important;
//     vertical-align: middle;
// }

.table-bordered td,
.table-bordered th {
  border: 1px solid #aaa;
}

.table thead th {
  border-bottom: none;
}

.table-bordered {
  border: 1px solid #aaa;
}

.modal-table table td {
  padding: 0.35rem;
}

.modal-table table td.label-bg {
  background-color: #efefef;
  width: 25%;
}

.modal-table table td.value {
  width: 8%;
}

.custom-dropdown a.btn-excel {
  margin: 5px 10px 0;
}

// .dataTables_wrapper div.dataTables_paginate .fa {
//     font-size: 22px;
//     line-height: 16px
// }

.page-link {
  border: none;
}

.page-item.active .page-link {
  z-index: 1;
  color: #003366;
  background-color: #fff;
  border-color: #fff;
  font-weight: 500;
}

.paddnig-left-desk {
  padding-left: 0;
}

@media (min-width: 768px) {
  .navTab a {
    font-size: 16px;
    padding: 0.5rem 1rem;
  }

  .paddnig-left-desk {
    padding-left: 15px;
  }
}

.custom-dropdown .dropdown-menu {
  min-width: 16rem;
  padding: 1rem;
  z-index: 5;
}

.filter-link {
  margin-left: 0px !important;
}

.input-search {
  background: #fff;
  border: 1px solid #ced4da;
  border-left: none;
  padding: 3px 10px;
}

.input-search .fa {
  color: #999;
}

.input-search:hover,
.input-search:active {
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
  border-left: none !important;
}

.body-header {
  border-bottom: 1px solid #c4c4c4;
  font-size: 22px;
  margin: 6px 0 6px 0;
  color: #036;
  float: left;
  width: 100%;
  padding: 0 0 15px;
  line-height: 42px;
  font-weight: 500;
}

/* .sub-header {
    font-size: 18px;
    color: #003366;
    margin-bottom: 5px;
    margin-top: 10px;
    padding-bottom: 4px;
    font-weight: 600;
} */

.header-label {
  font-size: 15px;
  padding-right: 10px;
}

.popup-header {
  font-weight: 600;
  font-size: 20px;
}

.back-btn {
  font-size: 32px;
  line-height: 34px;
  vertical-align: sub;
  color: #036;
  margin-right: 10px;
  cursor: pointer;
}

.btn-type:active {
  background-color: #fff;
}

.dtr-details li span {
  width: 50%;
}

.action-icon {
  font-size: 24px;
  line-height: 24px;
}

.info-icon {
  font-size: 24px;
  line-height: 24px;
  float: right;
}

.accordion-ex .card {
  border-radius: 0;
  border-color: #aaaaaa;
}

.accordion-ex a {
  text-decoration: none;
}

.accordion-ex .card-body {
  padding: 0 0 1.25rem 0;
}

.section-header {
  font: 15px;
  font-weight: 700;
}

/***************************Login Page **********************************************/

.login-label {
  background: linear-gradient(to right, #06c 0%, #009ce5 100%);
  position: relative;
  border-start-start-radius: 60px;
  border-end-start-radius: 60px;
}

// .login-label::after {
//   display: block;
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   background-image: none;
//   width: 235px;
//   height: 66px;
//   content: "";
// }

.logo {
  margin: 15px 0;
  float: left;
}

.logo-login {
  height: 57px;
  margin: 18px 0;
  width: 175px;
}

.login-container {
  margin: 30px 0;
}

.login-container .form-control {
  height: 40px;
  padding-left: 7px;
}

.login-container .input-group {
  display: table;
  border-collapse: separate;
  margin-bottom: 0;
}

.login-container .input-group input:focus {
  border-left: none;
}

.login-container .input-group .form-control:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: table-cell;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.login-container .input-group-addon {
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  border: 1px solid #06c;
  background-color: #06c;
  color: #fff;
  text-align: center;
  border-radius: 2px;
}

.login-container .input-group-addon:first-child {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.login-container input[type="email"] {
  width: 100%;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.login-container input[type="password"] {
  width: 100%;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.login-container input[type="text"] {
  width: 100%;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.login-container input[type="submit"] {
  width: 100%;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.login-container .btn-primary {
  margin-bottom: 10px;
  line-height: 2;
}

.login-container .field-validation-error {
  display: inline-block;
  color: red;
}

.login-container .has-error .form-control {
  border-color: #ccc;
}

.form-signin input[type="submit"] {
  display: block;
  margin-bottom: 10px;
  margin-left: 0;
  text-transform: uppercase;
}
/* *************************Login Page CSS*********************************************************** */
/* *********************Footer CSS******************************************************************* */
footer {
  background-color: #036;
  padding-bottom: 40px;
  text-align: center;
}

footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-additional ul,
.footer-additional li,
.footer-additional a {
  font-size: 14px;
}

.container-footer {
  background-color: #036;
  padding-right: 0;
  padding-left: 0;
  color: #fff;
}

.container-footer .row-fluid {
  margin-right: auto;
  margin-left: auto;
}

.footer-nav {
  margin-bottom: 30px;
}

.footer-nav a {
  display: block;
  border-bottom: 1px solid #004d99;
  text-transform: uppercase;
  line-height: 50px;
  color: #fff;
  font-weight: 700;
}

.footer-social {
  margin-bottom: 30px;
}

.footer-social a {
  color: #85bdec;
}

.footer-social a:not(:first-child) {
  margin-left: 20px;
}

.footer-social li {
  display: inline-block;
}

.footer-additional ul {
  margin-bottom: 30px;
  /*margin-right: 10%;
            margin-left: 10%;*/
}

.footer-additional a {
  color: #85bdec;
}

.footer-additional p {
  /*margin-right: 10%;
            margin-left: 10%;*/
  margin-bottom: 0;
}

.footer-additional li {
  display: inline-block;
}

.footer-additional li:not(:first-child) {
  margin-left: 20px;
}

.social-icon:not(:first-child) {
  margin-left: 10px;
}

.social-icon a {
  display: block;
  position: relative;
  width: 36px;
  height: 36px;
  overflow: hidden;
}

.cls-1vimeo {
  fill: #85bdec;
}

.cls-2vimeo {
  fill: none;
  stroke: #85bdec;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.cls-1facebook {
  fill: #85bdec;
}

.cls-2facebook {
  fill: none;
  stroke: #85bdec;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.cls-1linkedin {
  fill: #85bdec;
}

.cls-2linkedin {
  fill: none;
  stroke: #85bdec;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.privacy-text {
  float: left;
  margin-top: 30px;
  line-height: 20px;
  font-size: 13px;
}

.login-error .validation-summary-errors {
  color: #a94442;
  background-color: #f2dede;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ebccd1;
  border-radius: 4px;
}

@media (min-width: 768px) {
  footer {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .footer-nav {
    margin-bottom: 40px;
  }

  .footer-nav li {
    display: inline-block;
  }

  .footer-nav li:not(:first-child) {
    margin-left: 28px;
  }

  .footer-nav a {
    border-bottom: 0;
    text-transform: uppercase;
    line-height: 50px;
    color: #fff;
    font-weight: 700;
  }

  .footer-social {
    margin-bottom: 115px;
  }

  .footer-additional ul {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 15px;
  }

  .footer-additional p {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  footer {
    padding-bottom: 45px;
  }

  .footer-additional ul {
    margin-bottom: 0;
  }

  .footer-additional ul,
  .footer-additional li,
  .footer-additional a,
  .footer-additional p {
    font-size: 13px;
  }
}

/* *********************End Footer CSS******************************************************************* */
/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up)*/

@media (min-width: 768px) {
  .custom-navbar {
    min-height: 82px;
  }

  .top-box-right {
    border-right: 1px solid #dee2e6;
  }

  .brand-logo {
    height: 57px;
    width: 175px;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
}

/* Medium devices (tablets, 768px and up)*/

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 375px) {
  // div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  //     justify-content: center;
  // }

  .mt-sm {
    margin: 10px 10px;
    width: 97%;
  }
}

/***********************************************************/

/**************************stepper*******************************/
.wizard-stepper .item {
  text-align: center;
  position: relative;
  font-size: 0.8rem;
}

.wizard-stepper .item .step-count {
  display: block;
  position: relative;
  text-decoration: none;
}

.wizard-stepper .item .step-count::before,
.wizard-stepper .item .step-count::after {
  content: "";
  position: absolute;
  z-index: 0;
}

.wizard-stepper .item .step-count::after {
  background-color: #b3b3b3;
  width: 100%;
  height: 1px;
  margin-left: -50%;
  top: 50%;
}

.wizard-stepper .item .circle {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #b3b3b3;
  border-radius: 50%;
  margin: 0 auto;
  background: #fff;
  z-index: 1;
  position: relative;
  font-size: 0.8rem;
  font-weight: bold;
  color: #ff9900;
}

.wizard-stepper .item.active .circle {
  background: #ff9900;
  color: #fff;
  border: 1px solid #ff9900;
}

.wizard-stepper .item.active .step-count:after {
  background-color: #003366;
}

.wizard-stepper .item.completed .circle {
  background: #003366;
  color: #fff;
}

.wizard-stepper .item.completed .step-count:after {
  background-color: #003366;
}

.wizard-stepper .item.completed > div:before {
  content: "✓";
  top: 0;
  left: -5px;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  color: #003366;
}

@media (max-width: 768px) {
  .wizard-stepper .item > div {
    font-size: 0.6rem;
  }
}

@media (max-width: 480px) {
  .wizard-stepper .item > div {
    font-size: 0;
  }
}

/**************************End stepper*******************************/

.header-tab {
  font-size: 18px;
  font-weight: bold;
  color: #004987;
}

/***************************************************************/
.acco-header {
  font-weight: 200;
}

.div-bg {
  background-color: #f1f1f1;
  padding: 10px;
}

.quote-summary .card-body {
  padding: 15px 10px;
}
.quote-summary .form-group {
  margin-bottom: 0.5rem;
}
.sub-header {
  font-size: 16px;
  font-weight: bold;
  color: #004987;
}
.info-icon-size {
  font-size: 20px;
}
.fa-info-circle {
  cursor: pointer;
}
.primary-color {
  color: #0066cc;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0066cc;
}
.info-font {
  font-size: 13px;
  line-height: 18px;
  color: #5d5d5d;
}
.bg-border-none {
  background-color: #fff !important;
  border-top: none !important;
  border-bottom: none !important;
}
table.dataTableNew {
  border-bottom: 0;
}
table.table-bordered.dataTableNew tbody > tr:last-child td {
  border-bottom: 1px solid #aaa;
}

.quote-summary .pl-2 {
  margin-bottom: 0px;
  line-height: 1.5;
}
.empty-td {
  background-color: #fff;
  border-top: none !important;
  border-bottom: none !important;
}
.empty-th {
  background-color: #fff !important;
  border-top: none !important;
  border-bottom: none !important;
  width: 10px !important;
}

.quoteTable .table-responsive,
.bindVersion .table-responsive {
  border: none !important;
}
.quoteTable .table-responsive tr:last td,
.bindVersion .table-responsive tr:last td {
  border: 1px solid #dee2e6;
}
.quoteTable .table-responsive tr:last-child td,
.bindVersion .table-responsive tr:last-child td {
  border-bottom: 1px solid #dee2e6;
}
.quoteTable  th span,.bindVersion  th span{display: flex;}
.bindVersion td {
  text-align: center;
}
.bindVersion td:first-child {
  text-align: left;
}
.fa-trash-o.disabled {
  cursor: no-drop !important;
  color: #888;
  pointer-events: none;
}
.policyEndorsmentForms .is-hidden {
  display: none;
}
.ngx-dropdown-button.disabled {
  background-color: #ddd !important;
}
.ngx-dropdown-button.disabled {
  pointer-events: none !important;
}
.date-field.isDisabled {
  pointer-events: none !important;
}
.formDisabled {
  pointer-events: none !important;
  cursor: no-drop;
}
.bindVersion .text-center.disabled,
.bindVersion .radioCheckBoxMatrix.disabled {
  pointer-events: none;
}
.comment-note {
  margin-top: 10px;
}
.quoteTable .table.dataTable.no-footer tbody td,.bindVersion .table.dataTable.no-footer tbody td{
  width:20% !important;
}
.quoteTable .table.dataTable.no-footer thead th,.bindVersion .table.dataTable.no-footer thead th{
  width:20% !important;
}

.quoteTable .table.dataTable.no-footer thead th ,.bindVersion .table.dataTable.no-footer thead th{
  vertical-align: top !important;
}

.quoteTable th:first-child  span, .bindVersion th:first-child  span{
  display: flex;
  padding: 0px 18px 0px 0px !important;
  width: 100% !important;
}
.matrixTopLabel{
  min-height:62px;
}