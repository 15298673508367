// -----------------------------------------------------------------------------
// This file contains all Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
$text-font-stack: "thmcc", "Open Sans", "Arial", sans-serif !default;

/// text color
$text-color: #333 !default;
$azureRadiance: #009ce5;

/// Background color
$brand-color: rgb(229, 0, 80) !default;
$white: #fff;
$midnightBlue: #003366;
$seagull: #85bdec;
$gray: #666;
$darkGray: #999;
$lightGray: #dee2e6;
$error: #dc3545;

/// Container's maximum width
$max-width: 1170px !default;

/// Breakpoints map
$breakpoints: (
  "phone": 768px,
  "tablet-portrait": 1024px,
  "tablet-landscape": 1200px
) !default;

/// Relative or absolute URL where all assets are served from
$base-url: "libs/assets/" !default;
$filepath: $base-url + "fonts/";
$fontNames: "sourcesanspro-light", "sourcesanspro-regular", "sourcesanspro-it",
  "sourcesanspro-semibold", "sourcesanspro-bold";
