// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
  background: $white;
  padding-left: 58px;

  .top-header {
   /* background-color: $midnightBlue;*/
    padding: 10px 0;
  }

  .profile-dropdown {
    .dropdown-toggle {
      font-size: 15px;
     /* color: $white;*/
      display: flex;
      align-items: flex-start;
      padding: 0;
      font-weight: 400;

      &::after {
        margin-top: 0px;
        margin-left: 8px;
        border: 0 none;
        content: "\f078";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
      }

      @include on-event(true) {
        outline: 0 none;
        box-shadow: none;
        //background: darken($midnightBlue, 5%);
        text-decoration: none;
      }
    }

    .user-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 16px;

      span.role-name {
        display: block;
        font-size: 9px;
      }
    }

    .dropdown-menu {
      padding: 0;
      margin: 0;
      border-radius: 0;

      .dropdown-item,
      > .nav-link {
        line-height: 22px;
        padding: 5px 15px;
        border-bottom: 1px solid darken($white, 10%);
        display: block;
        clear: both;
        white-space: nowrap;
        color: $text-color;
        text-decoration: none;
        font-size: 14px;
        @include on-event {
          background-color: #f8f9fa;
        }
      }
    }
  }
  .brand-logo {
    width: 170px;
  }
  .navbar-nav {
    li {
      text-decoration: none;
      &:not(:last-child) {
        @include mediaQuery("phone") {
          border-bottom: 1px solid darken($white, 10%);
        }
      }
      a.nav-link {
        color: lighten($text-color, 10%);
        font-weight: normal;

        @include mediaQuery("phone") {
          padding: 10px 15px;
          color: $azureRadiance;

          @include on-event {
            color: $white !important;
            background: $midnightBlue !important;
          }
        }

        @include on-event {
          color: $azureRadiance;
        }
      }
      .active.nav-link {
        color: $azureRadiance;
        text-decoration: none !important;
        @include mediaQuery("phone") {
          color: $white;
          text-decoration: none !important;
        }
      }
    }
  }
  // .navbar-collapse {
  //   @include mediaQuery("phone") {
  //     background: darken($white, 5%);
  //     margin: 0px -16px 1px;
  //   }
  // }

  .top-navigation > .container {
    @include mediaQuery("phone") {
      padding: 0;
    }
  }
}

.login-label {
  background: linear-gradient(
    to right,
    darken($seagull, 35%) 0%,
    darken($seagull, 10%) 100%
  );
  position: relative;

  &::after {
    background-image: image("ribbon.png");
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 235px;
    height: 66px;
    content: "";
  }
  @include mediaQuery("phone") {
    &::after {
      background-image: none;
    }
  }
  h1 {
    color: $white;
    padding: 30px 0;
    margin: 0;

    @include mediaQuery("phone") {
      font-size: 24px;
    }
  }
}

.custom-navbar {
  .navbar-nav {
    @include mediaQuery("phone") {
      margin-top: 10px;
    }
  }
}
