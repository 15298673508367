// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.table {
  tr td,
  tr th {
    border: 1px solid $lightGray;
    // white-space: nowrap;
    font-size: 15px;
  }

  &.dataTable {
    margin-bottom: 0 !important;
    thead th,
    tbody td {
      padding: 12px 10px;
      font-size: 14px;
      // min-width: 60px;
    }

    & > tbody > tr.child span.dtr-title {
      min-width: 120px;
      text-transform: uppercase;
    }

    &.no-footer {
      border: 0 none;

      thead th {
        border-bottom: 1px solid $lightGray;
        /*text-transform: uppercase;*/
        padding: 10px 20px 10px 10px;
        border-right: 0 none;
        vertical-align: middle;
      }

      tbody td {
        border-bottom: 1px solid $lightGray;
        padding: 14px 10px;
        border-bottom: 0 none;
        border-right: 0 none;

        > div {
          max-width: 150px;
        }
      }
    }
    &.dtr-inline.collapsed > tbody > tr[role="row"] > td {
      &:first-child {
        padding-left: 40px;

        &:before {
          border-radius: 0;
          box-shadow: none;
          border: 0 none;
          background: $azureRadiance;
          top: 14px;
          left: 9px;
          height: 18px;
          width: 18px;
          line-height: 18px;
        }
      }
    }
    &.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before {
      background: $gray;
    }

    .form-control {
      width: 200px;
    }

    textarea.form-control {
      max-width: 200px;
    }

    .input-group {
      max-width: 160px;
    }
  }
}
.dataTables_wrapper {
  .dataTables_paginate {
    .paginate_button {
      color: $gray !important;
      border: 1px solid transparent;
      border-radius: 0;
      padding: 4px 14px;

      &.disabled,
      &.disabled:hover,
      &.disabled:active {
        cursor: default;
        color: $gray !important;
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none;
      }

      &.current {
        color: $white !important;
        border: 1px solid $azureRadiance;
        background: $azureRadiance;
      }

      &:hover,
      &.current:hover {
        color: $white !important;
        border: 1px solid $azureRadiance;
        background: $azureRadiance;
      }
    }
  }
  .dataTables_scroll {
    border: 1px solid #ddd;
  }
  .dataTables_scrollBody {
    border: 0 none !important;

    table > tbody > tr > td {
      vertical-align: top !important;
    }
  }
  .dataTables_paginate {
    padding: 15px 0 0 0;
  }
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
  max-width: 150px;
  overflow: visible;
  white-space: normal;
  word-break: break-all;
}
.quoteTable tbody td:last-child {
  border-right: 1px solid #dee2e6 !important;
}
.dataTables_wrapper {
  div.dataTables_paginate {
    .fa {
      font-size: 20px;
      vertical-align: middle;
    }
  }
}
.table.dataTable.no-footer tbody tr:last-child td {
  border-bottom: 1px solid $lightGray;
}
.table.dataTable.no-footer tbody td:last-child {
  border-right: 1px solid $lightGray;
}
.dataTables_scrollBody {
  overflow-y: hidden !important;
}
.dataTables_wrapper .dataTables_scroll {
  border: none;
}
