.sidebar-menu {
  border: 1px solid darken($azureRadiance, 4%);
  position: sticky;
  top: 20px;

  & > ul {
    list-style: none;

    li {
      &:not(:last-child) {
        border-bottom: 1px solid darken($azureRadiance, 4%);
      }
      &.active a {
        background: $azureRadiance;
        color: $white;
      }

      a {
        padding: 7px 5px 7px 10px;
        display: block;
        font-size: 14px;
        color: $azureRadiance;

        i {
          display: inline-block;
          margin-right: 3px;
        }

        @include on-event {
          text-decoration: none;
          background: $azureRadiance;
          color: $white;
        }
      }
    }
  }
}
