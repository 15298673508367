/*Pages Common CSS*/
.body-section {
  padding: 40px 0;

  .body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $darkGray;
    font-size: 30px;
    color: $midnightBlue;
    width: 100%;
    padding: 0 0 15px;
    line-height: 42px;
    font-weight: 500;
  }

  .more-search-link {
    text-decoration: underline;

    @include on-event {
      text-decoration: none;
    }
  }

  .filter-section {
    margin: 30px 0;

    .filter-head {
      font-size: 16px;
      font-weight: bold;
      color: $midnightBlue;
    }

    label {
      margin-bottom: 7px;
    }
  }
}

.search-step {
  margin: 10px 0 20px;
  padding-bottom: 30px;

  & > ul {
    padding: 0;
    margin: auto;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 600px;
    position: relative;

    @include mediaQuery("tablet-portrait") {
      max-width: 70%;
    }

    @include mediaQuery("phone") {
      max-width: 80%;
    }

    &::after {
      content: "";
      height: 1px;
      background: $darkGray;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      top: 50%;
      font-size: 14px;
    }

    li {
      a {
        height: 28px;
        width: 28px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $darkGray;
        border-radius: 100%;
        text-decoration: none;
        position: relative;
        font-size: 14px;
        color: #ff9900;

        & > span {
          position: absolute;
          top: 100%;
          display: block;
          white-space: nowrap;
          margin-top: 6px;
          font-size: 14px;
          color: $text-color;

          @include mediaQuery("phone") {
            font-size: 14px;
            display: none;
          }
        }
      }

      &.active > a {
        background: #ff9900;
        border: 1px solid #ff9900;
        color: $white;
      }
    }
  }
}
@include mediaQuery("phone") {
  .search-step {
    margin: 10px 0 10px;
    padding-bottom: 0;
  }
}
.button-row {
  padding: 0 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // &:not(.no-border) {
  //   border-top: 1px solid $lightGray;
  // }

  .btn {
    margin-left: 15px;
  }
}

.select-risk-section {
  .risk-info-box {
    border: 1px solid $lightGray;
  }

  .myaccordion {
    margin: 0px auto;
    box-shadow: none;

    .card {
      border: 0 none;
      padding: 0;
      border-radius: 0;

      &:not(:last-child) {
        border-bottom: 1px solid $lightGray;
      }
    }

    .card-header {
      display: block;
      background: lighten($lightGray, 10%);
      padding: 10px;
      border: 0 none;

      .btn-link {
        cursor: pointer;
        text-decoration: none;
        justify-content: space-between;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        color: $text-color;
        font-size: 15px;
        font-weight: bold;

        .fa {
          color: $text-color;
        }
      }
    }

    .card-body {
      padding: 15px;
      border: 0 none;

      .table {
        margin: 0;
      }
    }
    .collapse,
    .collapsing {
      border-top: 1px solid $lightGray;

      .collapse .card:last-child .card-body {
        border-bottom: 1px solid $lightGray;
      }
    }
    .collapse .card:last-child .card-body {
      border-bottom: 1px solid $lightGray;
    }
  }
  .risk-list {
    li {
      &:not(:last-child) {
        border-bottom: 1px solid $lightGray;
      }
      a {
        display: inline-block;
        padding: 10px;
      }
    }
  }
}
.delete-link {
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    text-decoration: underline;
  }

  i {
    margin-right: 5px;
    display: inline-block;
  }

  @include on-event {
    text-decoration: none;
    span {
      text-decoration: none;
    }
  }
}

.add-ec-info {
  margin-top: 40px;
}
#okta-sign-in.auth-container.main-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
