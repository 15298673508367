// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
  font-size: 16px;
  color: $white;
  padding: 6px 18px;
  text-transform: uppercase;
  border-radius: 0;

  &.btn-primary {
    background: $azureRadiance;
    border-color: $azureRadiance;
    border-radius: 21px;

    @include on-event {
      background: darken($azureRadiance, 5%);
      border-color: darken($azureRadiance, 5%);
      text-decoration: none;
    }
  }

  &.btn-secondary {
    background: transparent;
    border: 1px solid $midnightBlue;
    color: $midnightBlue;
    border-radius: 21px;

    @include on-event {
      background: $midnightBlue;
      color: $white;
      text-decoration: none;
    }
  }
}
