/* You can add global styles to this file, and also import other style files */
@import "../../../libs/assets/scss/main.scss";
@import "~font-awesome/css/font-awesome.css";

body {
  font-family: "Sans Pro", sans-serif, Arial, Univers;
  font-size: 15px;
  line-height: 1.66667;
  color: #222;
  background-color: #fff;
  font-weight: 400;
}

@font-face {
  font-family: "Sans Pro";
  font-weight: 300;
  font-style: normal;
  src: local("Source Sans Pro Light"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-Light.otf.woff2")
      format("woff2"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-Light.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Sans Pro";
  font-weight: 400;
  font-style: normal;
  src: local("Source Sans Pro Regular"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-Regular.otf.woff2")
      format("woff2"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-Regular.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Sans Pro";
  font-weight: normal;
  font-style: italic;
  src: local("Source Sans Pro Italic"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-It.otf.woff2")
      format("woff2"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-It.otf.woff")
      format("woff");
}
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
@font-face {
  font-family: "Sans Pro";
  font-weight: 500;
  font-style: normal;
  src: local("Source Sans Pro Semibold"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-Semibold.otf.woff2")
      format("woff2"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-Semibold.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Sans Pro";
  font-weight: 700;
  font-style: normal;
  src: local("Source Sans Pro Bold"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-Bold.otf.woff2")
      format("woff2"),
    url("../../policy-admin/src/assets/fonts/SansPro/SourceSansPro-Bold.otf.woff")
      format("woff");
}
h4 {
  font-size: 16px;
  color: #036;
  font-weight: normal;
}

option {
  min-height: 50px;
}

.nopadding {
  padding: 0;
}
.header-tab {
  font-size: 18px;
  font-weight: bold;
  color: #004987;
}
.required label:after {
  color: #e32;
  content: " *";
  display: inline;
  font-size: 16px;
}
.form-control {
  font-weight: 500;
  display: block;
  width: 100%;
  padding: 0.375rem 0.3rem;
  line-height: 20px;
  /* height: 34px; */
  color: #000;
  font-size: 16px;
  line-height: 1.66667;
  background-color: #fff;
  /* background-image: none; */
  border: 1px solid #ccc;
  border-radius: 2px;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #009ce5;
  border-color: #009ce5;
}

.btn {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 30px;
  margin-left: 4px;
  border-radius: 2px;
  border-width: 1px;
}
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #00618e;
  border-color: #004566;
}
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-image: none;
}
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0066cc;
  border-color: #0066cc;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0079b2;
  border-color: #0072a8;
}
.modal-table ::-webkit-input-placeholder {
  text-align: center;
}

.modal-table :-moz-placeholder {
  text-align: center;
}

.modal-table ::-moz-placeholder {
  text-align: center;
}

.modal-table :-ms-input-placeholder {
  text-align: center;
}

::-webkit-input-placeholder {
  text-align: left;
}

:-moz-placeholder {
  text-align: left;
}

::-moz-placeholder {
  text-align: left;
}

:-ms-input-placeholder {
  text-align: left;
}
#quoteNumber,
#BrokerNumber {
  pointer-events: none !important;
  background-color: #e9ecef !important;
}

// Hot fix
#topheader .container .profile-dropdown a {
  #headerUserName {
    padding-left: 5px;
  }
}
.hide-selected .selected-items {
  display: none;
}
.header-link {
  cursor: pointer;
  font-weight: 700;
}
.events-options::after {
  display: none;
}
.subjectivities-table table > thead > tr > th:nth-child(2) {
  width: 80px !important;
  text-align: center;
}
.subjectivities-table table > tbody > tr > td:nth-child(2) {
  text-align: center;
}
.event-info-table table > thead > tr > th:first-child {
  text-align: center;
}
input[type="checkbox"][readonly] {
  pointer-events: none;
}
.header-user-name::after {
  display: none !important;
}
.ngx-dropdown-container[_ngcontent-mda-c107] .disabled[_ngcontent-mda-c107] {
  pointer-events: none;
  background-color: #e9ecef !important;
  opacity: 1;
  cursor: no-drop;
}
.ngx-dropdown-container .ngx-dropdown-button.disabled {
  background-color: #e9ecef !important;
  pointer-events: none;
  cursor: no-drop;
}
.step2event-table {
  .dataTables_length,
  .dataTables_filter,
  .dataTables_info {
    display: none !important;
  }
}
.isQuoteDownloadFormInput .form-control[readonly] {
  background-color: #fff;
  opacity: 1;
  cursor: default;
}
