// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $azureRadiance;
  text-decoration: none;

  @include on-event {
    color: darken($azureRadiance, 10%);
    text-decoration: underline;
  }
}

table,
table tr th,
table tr td {
  font-size: 14px;
}

.table-responsive {
  border: 1px solid $lightGray;
}
.table-responsive > table {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
